export default function BlogList() {
  return (
    <div className="sr-only">
      <ul>
        <li><a href='https://charmelle.london/articles/lip-enhancement/'>Juvederm Lip Enhancements At Charmelle London</a></li>
        <li><a href='https://charmelle.london/articles/laser-hair-removal-for-dark-skin/'>Laser Hair Removal for Dark Skin</a></li>
        <li><a href='https://charmelle.london/articles/unwanted-fat-or-cellulite/'>Unwanted Fat or Cellulite</a></li>
        <li><a href='https://charmelle.london/articles/how-to-care-for-your-eyebrows/'>How To Care For Your Eyebrows</a></li>
        <li><a href='https://charmelle.london/articles/should-you-add-cactus-to-your-skincare-regime/'>Should You Add Cactus To Your Skincare Regime?</a></li>
        <li><a href='https://charmelle.london/articles/nows-the-perfect-time-to-focus-on-skincare/'>Now’s The Perfect Time To Focus On Skincare</a></li>
        <li><a href='https://charmelle.london/articles/cheek-enhancement/'>Cheek Enhancements At Charmelle London with Juvederm</a></li>
        <li><a href='https://charmelle.london/articles/instant-facial-contour-enhancement/'>Instant Contour Enhancements At Charmelle London with Juvederm</a></li>
      </ul>
    </div>
  );
}
